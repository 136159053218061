.custom-file-input-label {
  display: flex;
  align-items: center;
  font-weight: 600;
  flex-direction: column;
  cursor: pointer;
  border-radius: 12px;
  padding: 8px;
}

.custom-file-input {
  display: none;
}
