.sales-container {
  height: calc(100vh - 32px);
  overflow-y: scroll;
  padding: 1rem;
}

.table-cell {
  font-size: 14px;
}

td.sales-no-border {
  border-bottom: none;
}
