.add-dish-hours {
  display: inline-block;
  width: 100%;
  margin-top: 12px;
}
.add-dish-hours-from {
  display: inline-block;
  width: calc(50% - 6px);
  margin-right: 12px;
}
.add-dish-hours-to {
  display: inline-block;
  width: calc(50% - 6px);
}

.add-item-dialog-title {
  position: relative;
}

.add-item-dialog-close {
  position: absolute;
  top: 0;
  right: 0;
  color: #424242;
}

.add-menu-item-price-container {
  padding: 8px;
  font-weight: 500;
  margin-top: 8px;
}

.add-menu-item-price-display {
  text-decoration: underline;
  padding-left: 12px;
}

.add-menu-item-displayed-name {
  padding-left: 12px;
}

.add-dish-file-input {
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0px auto;
}

@media only screen and (max-width: 560px) {
  .add-dish-file-input {
    flex-direction: column-reverse;
  }
  .add-dish-file-results {
    grid-template-columns: 100% !important;
  }
}

.add-dish-file-results {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
}
