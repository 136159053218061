.update-dish-file-input {
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.update-dish-file-results {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
}

.update-item-dialog-title {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-bottom: solid 1px;
}

.update-item-dialog-close {
  position: absolute;
  top: 0;
  right: 0;
}

.update-item-dialog-buttons-container {
  padding: 48px;
  padding-top: 0;
  padding-bottom: 20px;
}
@media only screen and (max-width: 560px) {
  .update-dish-file-input {
    flex-direction: column-reverse;
  }
  .update-dish-file-results {
    grid-template-columns: 100% !important;
  }
}
