.tag {
  background-color: #f50057;
  padding: 1px 4px;
  font-size: 0.6em;
  line-height: 0.5;
  color: #fff;
  font-weight: 600;
  margin-left: 5px;
}

.info-container {
  text-align: center;
}

.info-header {
  margin: 10px;
}

.info-value {
  font-weight: bold;
}
