.timeline-container {
  position: relative;
}

.vertical-line {
  content: "";
  position: absolute;
  top: 0;
  left: 38px;
  height: 100%;
  width: 4px;
  z-index: -1;
  background: #cfd8dc;
}

.timeline {
  height: calc(100vh - 32px);
  overflow-y: scroll;
  padding: 1rem;
}

.timeline-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.timeline-entry:first-child {
  margin-top: 0;
}

.timeline-entry:last-child {
  margin-bottom: 0;
}

.timeline-content {
  font-size: 0.8rem;
  line-height: 1.2rem;
  background-color: #fff;
  padding: 1em;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  width: 80%;
  border: 4px solid #fff;
  box-sizing: border-box;
}

.timeline-img {
  background: #cfd8dc;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.timeline-time {
  color: #9e9e9e;
}

.activity-empty {
  text-align: center;
  margin-top: 4rem;
}
