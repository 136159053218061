.section-header {
  font-size: 0.9em;
  color: #9e9e9e;
  padding: 12px;
}

.availability-container {
  height: calc(100vh - 32px);
  overflow-y: auto;
  padding: 1rem;
}

.add-dish-button-container {
  position: fixed;
  z-index: 100;
  bottom: 0px;
  margin: 8px;
}
